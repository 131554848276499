<template>
  <b-row>
    <b-col>
      <div class="d-flex justify-content-between">
        <h2>Mi trabajo</h2>
        <b-button 
          class="button-proposal" 
          @click="$router.push({name: 'step_category'})"
          :disabled="!utils.getUserData().is_superuser"
        >{{ $t("dashboard.createProposal") }}</b-button>
      </div>
      <b-tabs content-class="mt-3"  v-model="active_tab">
        <b-tab title="Escritorio" disabled><p>I'm the first tab</p></b-tab>
        <b-tab title="Mis propuestas" :active="$route.params.section === 'proposal'">
          <my-proposals />
        </b-tab>
        <b-tab title="Pitches en campañas" disabled><p>I'm a disabled tab!</p></b-tab>
        <b-tab title="Transacciones" disabled><p>I'm a disabled tab!</p></b-tab>
        <b-tab title="Mis redes sociales" :active="$route.params.section === 'networks'">
          <profile-networks></profile-networks>
        </b-tab>
      </b-tabs>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BTabs,
  BTab,
  BButton,
} from 'bootstrap-vue';
import myProposals from '@/views/pages/creator/myProposals.vue';
import profileNetworks from '@/views/pages/creator/profileNetworks.vue';
import utils from '@/libs/utils'

export default {
  name: 'myWork',
  components: {
    BRow,
    BCol,
    BTabs,
    BTab,
    BButton,
    myProposals,
    profileNetworks,
  },
  data() {
    return {
      active_tab: 0,
      utils,
    }
  }
}
</script>
<style>
.button-proposal {
  align-items: center;
  background-color: #55a6c4 !important;
  border:none;
}
</style>