<template>
  <b-row class="border-top">
    <b-col class="col-12 col-md-6 col-lg-3 pt-3">
      <div class="d-flex justify-content-center flex-wrap border-bottom pb-3">
        <b-avatar :src="utils.getAssetUrl(profile.profile_image)" size="130px"></b-avatar>
        <strong class="w-100 h2 text-center mt-2">{{profile.username}}</strong>
        <p class="text-muted">{{utils.getFormat(getSumFollowers())}}</p>
        <b-avatar-group size="1.8rem" class="d-flex justify-content-center col-12">
          <b-avatar 
            v-for="(network, index) in profile.networks" 
            target="_blank"
            :key="index"
            :src="utils.getImageNetworkColor(network.network)" 
            class="avatar-network-work"
            :href="getLinkNetwork(network)"
          >
          </b-avatar>
        </b-avatar-group>
      </div>
    </b-col>

     <b-col class="col-12 col-md-6 col-lg-5">
      <div class="card-analytics-profile-net p-3 border-left border-right">
        <h4 class="border-bottom pb-1">Analytics</h4>
        <b-row class="mt-1">
          <b-col>
            <div class="border-box-analytics">
              <p>ENG. RATE</p>
              <strong>{{profile.er !== null ? `${profile.er} %` : '--'}}</strong >
            </div>
          </b-col>
          <b-col>
            <div class="border-box-analytics">
              <p>{{$t('creator.avg_er_post')}}</p>
              <strong>N/A</strong >
            </div>
          </b-col>
        </b-row>
      </div>
    </b-col>

    <view-connections-card :networks="profile.networks" class="col-12 col-md-6 col-lg-4 p-3"></view-connections-card>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BAvatarGroup,
  BAvatar,
} from 'bootstrap-vue';
import service_others from "@/services/others";
import utils from '@/libs/utils';
import viewConnectionsCard from '@/views/components/creator/viewConnectionsCard.vue';

export default {
  name: 'profileNetworks',
  components: {
    BRow,
    BCol,
    BAvatarGroup,
    BAvatar,
    viewConnectionsCard,
  },
  data() {
    return {
      profile: {
        networks: [],
      },
      utils,
    }
  },
  created() {
    this.getProfile();
    this.getSumFollowers()
  },
  methods: {
    getProfile() {
      const user = JSON.parse(localStorage.userData);
      service_others.getProfile().then((response) => {
        this.profile = response.user;
        utils.setuserData(response, user.ability);
      });
    },
    getSumFollowers() {
      let sum = 0;
      this.profile.networks.forEach((i) => {
        sum += i.followers;
      });
      return sum;
    },
    getLinkNetwork(profile) {
      if (profile.network === 'facebook' && profile.link) return profile.link
      return utils.getURL(profile.network, profile.username, profile.is_channel)
    },
  }
}
</script>

<style> 
.border-box-analytics {
  border: 1px solid #eaeaea;
  border-radius: 1em;
  padding: 0.5em;
  box-shadow: 0rem 0.375rem 0.75rem rgb(108 117 125 / 8%);
}
.avatar-network-work {
  padding: 0.2em;
  background-color: white !important;
}
.avatar-network-work:hover {
  width: 2rem !important;
  height: 2rem !important;
  background-color: white !important;
}
.avatar-network-work:focus {
  background-color: white !important;
}
.card-analytics-profile-net {
  height: 100%;
  background-color: white;
}
</style>