<template>
  <b-row>
    <b-col class="col-12 col-md-6 col-lg-3">
      <b-card class="overflow-hidden">
        <div class="background-left-proposal default-color-background">
        </div>
        <h6>ACTIVE TOOLS</h6>
        <h2>--</h2>
        <p class="text-muted">-- waiting on Buyer</p>
      </b-card>
    </b-col>

    <b-col class="col-12 col-md-6 col-lg-3">
      <b-card class="overflow-hidden">
        <div class="background-left-proposal default-color-background">
        </div>
        <h6>NEW ORDERS</h6>
        <h2>--</h2>
        <p class="text-muted">-- Active orders</p>
      </b-card>
    </b-col>

    <b-col class="col-12 col-md-6 col-lg-3">
      <b-card class="overflow-hidden">
        <div class="background-left-proposal default-color-background">
        </div>
        <h6>ADMIN CHANGE REQUESTS</h6>
        <h2>--</h2>
        <p class="text-muted">-- In Admin review</p>
      </b-card>
    </b-col>

    <b-col class="col-12 col-md-6 col-lg-3">
      <b-card class="overflow-hidden">
        <div class="background-left-proposal default-color-background">
        </div>
        <h6>POTENCIAL EARNINGS</h6>
        <h2>--</h2>
        <p class="text-muted">-- Pending payout</p>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCol,
  BRow,
  BCard,
} from 'bootstrap-vue';

export default {
  name: 'myProposals',
  components: {
    BCard,
    BCol,
    BRow,
  }
}
</script>

<style>
  .background-left-proposal {
    width: 10px;
    height: 220px;
    position: absolute;
    top: 0;
    left: 0px;
  }
  .default-color-background {
    background: linear-gradient(180deg, #50D0AD 0%, #29809A 100%);
  }
</style>